<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <!-- {{ title }} -->
        <div class="market-main-sub-tabs w-scroll-style">
            <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :class="'market-main-sub-tab-item ' + (item.name == tabName ? 'active' : '') ">
                 <router-link :to="{name: item.name, params: { name: $route.params.name }, }">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div>
    </div>
    <MarketCharts></MarketCharts>
</div>
</template>


<script>
// این ویو برای ماژول بازار بخش پروفایل (چارت ها و نمودار های) مورد استفاده قرار میگیرد
import MarketCharts from "@/components/Market/Profile/Charts";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Market/Profile/Header';

export default {
    name: "marketCharts",
    components: {
        MarketCharts,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketCharts',
        };
    },
    mounted() {
    },
};
</script>
